import React, { useEffect, useState } from "react";
import { When, Unless } from "react-if";
import QRCode from "react-qr-code";
import { createPortal } from "react-dom";

import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useCreateEnelContext } from "../../provider/createEnel.provider";
import {
  useTouchIDInfoService,
  useTouchIDService,
} from "../../../../../services/microservice/enel/touchID/use-touchID";
import { Skeleton } from "@mui/material";
import { useStepCreateEnelContext } from "../stepper.provider";
import ERRORS from "../../../components/errors";
import { useMobile } from "../../../../../hooks/useMobile";

interface ITouch {
  touchUrl: string;
  touchId: string;
}

const TouchStepComponent = () => {
  const { device, setTouchID } = useCreateEnelContext();
  const { setCurrentStep } = useStepCreateEnelContext();
  const {
    mutateAsync: getTouchID,
    isSuccess,
    isLoading: isLoadingGetTouch,
  } = useTouchIDService();
  const { mutateAsync: getInfoTouchID, isLoading } = useTouchIDInfoService();
  const [touchInfo, setTouchInfo] = useState<ITouch>();
  const [copy, setCopy] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openTouch, setOpenTouch] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const { isMobile } = useMobile();

  const handleGetTouchID = async () => {
    setError("");
    setCount(0);
    try {
      const res = await getTouchID({
        imei: device?.imei,
        sponsorID: "ENEL",
        sendEmail: false,
      });
      setTouchInfo(res.data);
      setTouchID(res.data.touchId);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePreviusStep = () => {
    setCurrentStep(3);
  };
  const handleNextStep = () => {
    setCurrentStep(5);
  };
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(touchInfo.touchUrl);
    setCopy(true);
  };
  const handleValidate = async () => {
    try {
      const res = await getInfoTouchID({
        sponsorID: "ENEL",
        touchID: touchInfo.touchId,
      });
      if (res.data.isValidTouch) {
        setValidate(true);
        setError("");
      } else {
        setError(ERRORS.ERROR_TOUCH);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        setError(ERRORS.ERROR_TOUCH);
      } else {
        setError(ERRORS.INTERNAL);
      }
    }
  };

  useEffect(() => {
    handleGetTouchID();
  }, []);

  const handleOpenTouchTest = () => {
    document.getElementById("root").style.overflow = "hidden";
    document.getElementById("root").style.height = "100vh";
    setOpenTouch(true);
  };

  const receiveMessage = (e) => {
    setCount((prevState) => prevState + 1);
    onReceiveMessage(e);
  };

  const onReceiveMessage = (e) => {
    if (e.data.type === "close-iframe" && count === 0) {
      setOpenTouch(false);
      handleValidate();
      document.getElementById("root").removeAttribute("style");
      window.scrollTo(0, 750);
    }
  };

  window.addEventListener("message", receiveMessage, false);

  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        4. <span style={{ color: "#461E7D" }}>Prueba</span> de dispositivo
      </h2>
      {openTouch &&
        createPortal(
          <div
            className={
              "fixed top-0 z-50 h-screen w-screen overflow-hidden bg-background"
            }
          >
            <iframe
              title="testTouch"
              src={`${touchInfo.touchUrl}&postMessage=true`}
              scrolling={"no"}
              frameBorder={"0"}
              height={document.documentElement.clientHeight}
              width={document.documentElement.clientWidth}
              allow="fullscreen"
            />
          </div>,
          document.body,
        )}
      <div className="mb-10 w-full">
        {isMobile ? (
          <p className="text-xl font-normal">
            Para seguir, haz clic en el botón “Comenzar prueba”. Cuando
            termines, regresa a esta pantalla y haz clic en “Siguiente”.
          </p>
        ) : (
          <p className="text-xl font-normal">
            Para continuar, realiza una prueba en el celular que deseas
            registrar. Escanea el código QR con el celular a registrar o copia
            el enlace del siguiente botón en una nueva pestaña del navegador del
            celular a registrar.
          </p>
        )}
        <When condition={!isSuccess}>
          <When condition={isMobile}>
            <div className="flex w-full justify-center">
              <Skeleton variant="rounded" width={150} height={40} />
            </div>
          </When>
          <Unless condition={isMobile}>
            <div className="flex w-full justify-center">
              <Skeleton variant="rectangular" width={150} height={150} />
            </div>
          </Unless>
        </When>
        <Unless condition={!isSuccess}>
          <div className={`flex w-full flex-col items-center justify-center`}>
            <When condition={isMobile}>
              <PrimaryButton
                title={"Comenzar prueba"}
                onClick={() => handleOpenTouchTest()}
                className="mt-2 rounded-2xl px-5 text-white"
                style={{
                  backgroundColor: "#461E7D",
                  opacity: !error ? 1 : 0.5,
                }}
                disabled={error !== ""}
              />
            </When>
            <Unless condition={isMobile}>
              <QRCode
                value={touchInfo?.touchUrl}
                className="my-5 hidden h-40 w-40 md:block"
              />
            </Unless>
            <div
              className={`mb-5 mt-4 flex w-full ${isMobile ? "justify-center" : "justify-between"}`}
            >
              <PrimaryButton
                title={
                  isMobile ? "Generar nueva prueba" : "Generar nuevo código QR"
                }
                onClick={() => handleGetTouchID()}
                className="rounded-2xl px-5 text-white"
                style={{ backgroundColor: "#461E7D" }}
                loading={isLoadingGetTouch}
              />
              {!isMobile && (
                <PrimaryButton
                  title={copy ? "Copiado" : "Copiar enlace"}
                  onClick={() => handleCopyUrl()}
                  className="rounded-2xl px-5 text-white"
                  style={{ backgroundColor: "#461E7D" }}
                />
              )}
            </div>
          </div>
        </Unless>
        {!isMobile && (
          <p className="text-xl font-normal">
            Una vez finalizada la prueba, realiza la validación en el siguiente
            boton, con esto seguir con el proceso de activación de cobertura.
          </p>
        )}
        <div className="my-3 flex w-full justify-center">
          {validate ? (
            <div className="ml-10 flex items-center">
              <div className="mr-2 h-10 w-10">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>
              <p className="text-lg">Tu prueba fue validada exitosamente</p>
            </div>
          ) : (
            <PrimaryButton
              title={validate ? "Validado" : "Validar"}
              onClick={() => handleValidate()}
              className="hidden rounded-2xl px-5 text-white md:block"
              style={{ backgroundColor: "#461E7D" }}
              loading={isLoading}
            />
          )}
        </div>

        {error && <p className="text-lg text-critical">{error}</p>}
      </div>
      <div className="mt-4 flex justify-between">
        <PrimaryButton
          title="Atras"
          className="rounded-2xl border px-5 text-textBlack"
          type="button"
          style={{ borderColor: "#461E7D" }}
          onClick={() => handlePreviusStep()}
        />
        <PrimaryButton
          title="Siguiente"
          className="rounded-2xl px-5 text-white"
          style={{ backgroundColor: "#461E7D", opacity: validate ? 1 : 0.5 }}
          disabled={!validate}
          onClick={() => handleNextStep()}
        />
      </div>
    </section>
  );
};

export default TouchStepComponent;
